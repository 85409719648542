const API_URL = process.env.GATSBY_WORDPRESS_URL + 'wp/graphql';

const headers = { 'Content-Type': 'application/json' };

export async function fetchAPI(query, variables) {
	const body = JSON.stringify({ query, variables });
	const res = await fetch(API_URL, { body, headers, method: 'POST' });
	const json = await res.json();
	if ('errors' in json) {
		console.error(json.errors);
		throw new Error(json.errors.map((e) => e.message).join('\n'));
	}

	return json.data;
}
