import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './ListItem.module.scss';

const c = classNames.bind(styles);

function ListItem({ className, data, path }) {
	return (
		<Link to={path} className={c('wrapper', className)}>
			<div className={c('imageContainer')}>
				<img
					width={672}
					height={376}
					loading="lazy"
					className={c('image')}
					src={data.header.image.url}
					alt={data.header.image.title}
				/>
				<div className={c('texts')}>
					<h2 className={c('title')}>
						{data.header.title && data.header.title + '.'}
					</h2>
					<p
						className={c('description')}
						dangerouslySetInnerHTML={{ __html: data.description_list }}
					/>
				</div>
			</div>
		</Link>
	);
}

export default ListItem;
