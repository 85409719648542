import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from 'gatsby';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import { fetchAPI } from '../../../utils/api';
import { LanguageContext } from '../../../utils/LanguageContext';
import { useMounted } from '../../../utils/useMounted';
import * as styles from './List.module.scss';
import ListItem from './ListItem/ListItem';

const c = classNames.bind(styles);

const limit = 6;

function List({ className, nodes, lang, type }) {
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query {
			wp {
				sp {
					strings {
						listing_loading_label {
							en
							fr
						}
						listing_load_more_label {
							en
							fr
						}
					}
				}
			}
		}
	`);
	const strings = query.wp.sp.strings;
	const { isMounted } = useMounted();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [morePosts, setMorePosts] = useState([]);
	const [hasnextPage, setHasNextPage] = useState(false);

	const endCursor = useRef('');

	const posts = morePosts.length ? [...morePosts] : [...nodes];

	const chunks = new Array(Math.ceil(posts.length / limit))
		.fill()
		.map(() => posts.splice(0, limit));

	const loadMore = useCallback(async () => {
		setLoading(true);
		setError('');

		try {
			const {
				data: { pageInfo, nodes },
			} = await fetchAPI(
				/* GraphQL */ `
					query MyQuery(
						$first: Int
						$after: String
						$language: LanguageCodeFilterEnum
					) {
						data: ${type.toLowerCase()}All(
							first: $first
							after: $after
							where: {
								language: $language,
								orderby: { field: DATE, order: DESC }
							}
						) {
							pageInfo {
								endCursor
								hasNextPage
							}
							nodes {
								uri
								acf: acfSingle${type} {
									description_list
									header {
										title
										description
										image {
											url
											title
										}
									}
								}
							}
						}
					}
				`,
				{ first: limit, after: endCursor.current, language: lang },
			);

			if (!isMounted.current) return;
			endCursor.current = pageInfo.endCursor;
			setHasNextPage(pageInfo.hasNextPage);
			setMorePosts((posts) => [...posts, ...nodes]);
		} catch (e) {
			if (isMounted.current) setError(e);
		} finally {
			if (isMounted.current) setLoading(false);
		}
	}, [isMounted, lang, type]);

	useEffect(() => {
		loadMore();
	}, [loadMore]);

	return (
		<section className={c('wrapper', className)}>
			{chunks.map((chunk, i) => (
				<div key={i} className={c('chunk')}>
					{chunk.map((item, o) => (
						<ListItem
							key={o}
							path={item.uri}
							className={c('item')}
							data={item.acf}
						/>
					))}
				</div>
			))}

			<button
				onClick={loadMore}
				disabled={loading}
				className={c('loadMore', { hidden: !hasnextPage })}
			>
				{loading
					? language.languageCode === 'FR'
						? strings.listing_loading_label.fr
						: strings.listing_loading_label.en
					: language.languageCode === 'FR'
						? strings.listing_load_more_label.fr
						: strings.listing_load_more_label.en}
			</button>

			{error && <p className={c('error')}>{error.message}</p>}
		</section>
	);
}

export default List;
